import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/component/banner/BannerSection.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/component/header/HeaderSection.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/component/modal/SearchFormModal.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/component/modal/VideoModal.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/component/portfolio/PortfolioSection.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/component/service/ServiceSection.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/component/testimonial/TestimonialSection.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/component/utils/CustomGsapHeaderAnimate.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/component/utils/DivAnimateYAxis.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/component/utils/NumberCounter.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/component/video/VideoSection.tsx")